import React from 'react';

const longText = (
    <div>
        <p>
            Namen lekarne je, da vam zagotavlja kakovostno in učinkovito preskrbo z zdravili in drugimi izdelki za podporo zdravljenja in ohranitev zdravja. V ta namen sodelujemo z vsemi veledrogerijami registriranimi v Sloveniji.
        </p>
        <br />
        <p>
            Poleg zdravil ima lekarna na zalogi prehranska dopolnila, medicinske pripomočke in kozmetične proizvode. Tudi te naročamo preko registriranih veledrogerij. Za dosego kazalcev kakovosti shranjujemo vse v kontroliranih pogojih.
        </p>
        <br />
        <p>
            Delež pokritosti obiskovalcev lekarne z magistri farmacije z veljavno licenco je 100%. V lekarni so zaposleni trije magistri farmacije in farmacevtski tehnik.
        </p>
        <br />
        <p>
            Ker je delež pokritosti obiskovalcev s farmacevti 100%, nam omogoča kakovostno obvladovati farmakovigilanco, to je pazljiv, skrben in previden pristop pri izdaji zdravil. Lahko si vzamemo tudi dovolj časa za razgovor z bolnikom o zdravilu (komplianca). Za nas je najpomembnejši kazalec kakovosti ravno komplianca, ki omogoča, da vam razumljivo podamo navodila in vse, kar je potrebno za varno in učinkovito uporabo zdravil.
        </p>
        <br />
        <p>
            V letih 2021, 2022, 2023 opažamo, da je ob pomanjkanju družinskih zdravnikov dostopnost do recepta otežkočena. Menimo, da morajo imeti bolniki možnost za neprekinjeno zdravljenje. Trudimo se, da jim pri tem pomagamo.
        </p>
    </div>
);

const ReadMoreTextComponent = () => {
    return <div>{longText}</div>;
};

export default ReadMoreTextComponent;