import React from "react";
import {Helmet} from "react-helmet";

import Navigation from "../components/Navigation";
import HeroImage from "../components/HeroImage";
import WorkTime from "../components/WorkTime";

import Footer from "../components/Footer";
import Compliance from "../components/compliance"
import Contacts from "../components/Contacts"
import {Container, Row, Col} from "react-bootstrap";

import lek2 from "../images/lek2.jpg";


const AboutScreen = () => {
    return (
        <>
            <Helmet>
                <title>Lekarna Dezman</title>
            </Helmet>
            <Container>
                <Navigation/>
            </Container>
            <HeroImage image={lek2}/>
            <Container>
                <div className="main-section">
                    <Row>
                        <Col xl={3}>
                            <WorkTime/>
                        </Col>
                        <Col xl={9}>
                            <h2 className="text-center">Kazalci kakovosti</h2>
                            <Compliance/>
                        </Col>
                    </Row>
                </div>
                <Contacts/>
            </Container>
            <Footer/>
        </>
    );
};

export default AboutScreen;
