import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../images/lek1.png";
import logoMobile from "../images/lek1.png";
import mobile from "../images/telefon.svg";
import {Container, Row, Col} from "react-bootstrap";


const Navigation = () => {
  return (
      <>
        <Navbar collapseOnSelect expand="lg" className="navbar">
          <Container>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Navbar.Brand>
                <Link to="/">
                  {window.innerWidth < 500 ? (
                      <img
                          alt="Lekarna Dežman"
                          src={logoMobile}
                          className="d-inline-block align-top mobile-logo"
                      />
                  ) : (
                      <img
                          alt="Lekarna Dežman"
                          src={logo}
                          className="d-inline-block align-top logo"
                          style={{ maxWidth: '50px' }}
                      />
                  )}
                </Link>
              </Navbar.Brand>

              <div className="mx-auto text-center">
                <h1>LEKARNA DEŽMAN</h1>
              </div>

              <Nav className="ml-auto">
              <span className="mobile">
                <img
                    src={mobile}
                    alt="mobile-icon"
                    className="mobile-icon"
                />
                <span className="mobile-number">
                  <a href="tel:01 565 7555">01 565 7555</a>
                </span>
              </span>
              </Nav>
            </div>
          </Container>
        </Navbar>
      </>
  );
};

export default Navigation;


