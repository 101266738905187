import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Contacts = () => {
  return (
    <div className="contacts">
      <Container>
        <Row>
          <Col xs={12} md={4} lg={4} className="contact">
            <p className="contact-text">Lekarna Dežman</p>
            <p className="contact-text">Bratovševa ploščad 30</p>
            <p className="contact-text">1000 Ljubljana</p>
          </Col>

          <Col xs={12} md={4} lg={4} className="contacts-middle contact">
            <p className="contact-text">
              <a href="tel:01 565 75 55">TEL.: 01 565 7555</a>
            </p>
            <p className="contact-text">
              <a href="mailto: dezman-kocjan@siol.net">
                MAIL: dezman-kocjan@siol.net
              </a>
            </p>
          </Col>

          <Col xs={12} md={4} lg={4} className="contacts-right contact">
            <p className="contact-text contact-text-bold">DELOVNI ČAS:</p>
            <p className="contact-text">PON - PET: 8:00 - 19:00</p>
            <p className="contact-text">SOB: 8:00 - 12:00</p>
            <p className="contact-text">ZAPRTO: NEDELJA IN PRAZNIKI</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contacts;
