import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import AboutScreen from "./screens/AboutScreen";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/">
            <AboutScreen />
          </Route>
         
        </Switch>
      </Router>
    </>
  );
}

export default App;
